import { POINT_MATRIX_SET_CONSTANTS } from './constants/pointMatrixConstants';

export const urls = {
    POINT_MATRIX: 'pointmatrix',
    ADD_USER_MANAGEMENT: 'users',
    WAREHOUSES: 'master/demea/warehouses',
    BYPASS_LOCATION: 'inspectionbypass/quartile'
};

class APIEndPoints {
    static POINT_MATRIX_POST = () => {
        return `/${urls.POINT_MATRIX}`;
    };

    static POINT_MATRIX_GET = (
        page: number,
        size: number,
        districtGroup?: string,
        commodityCode?: string,
        estimateGroup?: string,
        variety?: string,
        attributeType?: string,
        status?: string,
        modifiedBy?: string
    ) => {
        const baseUrl = `/${urls.POINT_MATRIX}`;
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            businessunit: 'DEMEA'
        });

        if (districtGroup) {
            params.append('districtgroup', districtGroup);
        }

        if (commodityCode) {
            params.append('commoditycode', commodityCode);
        }

        if (estimateGroup) {
            params.append('estimategroup', estimateGroup);
        }

        if (attributeType) {
            params.append('attributetype', attributeType);
        }

        if (status) {
            params.append('status', status);
        } else {
            params.append('status', POINT_MATRIX_SET_CONSTANTS.ALL);
        }

        if (variety) {
            params.append('varietycode', variety);
        }

        if (modifiedBy) {
            params.append('modifiedby', modifiedBy);
        }

        return `${baseUrl}?${params.toString()}`;
    };

    static USER_MANAGEMENT_GET = (page?: number, size?: number, warehouse?: string, email?: string, createdby?: string, createddate?: string) => {
        const baseUrl = `/users`;
        const params = new URLSearchParams({
            page: page ? page.toString() : '1',
            size: size ? size.toString() : '50'
        });

        if (warehouse) {
            params.append('warehouse', warehouse);
        }

        if (email) {
            params.append('email', email);
        }

        if (createdby) {
            params.append('createdby', createdby);
        }

        if (createddate) {
            params.append('createddate', createddate);
        }

        return `${baseUrl}?${params.toString()}`;
    };

    static USER_MANAGEMENT_POST = () => {
        return `/${urls.ADD_USER_MANAGEMENT}`;
    };

    static GET_WAREHOUSES = () => {
        return `/${urls.WAREHOUSES}`;
    };

    static BYPASS_LOCATION_POST = () => {
        return `/${urls.BYPASS_LOCATION}`;
    };

    static BYPASS_LOCATION_GET = (warehousenbr: string, berrytype: string, percentage: string) => {
        const baseUrl = urls.BYPASS_LOCATION;
        const params = new URLSearchParams({
            warehousenbr: warehousenbr,
            berrytype: berrytype,
            percentage: percentage
        });
        return `${baseUrl}?${params.toString()}`;
    };
}

export default APIEndPoints;
