import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { DrcMain, DrcImage } from 'driscolls-react-components';
import { setPageTitleAction } from '../actions/actions';
import '../../index.scss';
import styles from './Home.module.css';
import { DriscollsLogin, useAuth, withAuth, DriscollsAuthenticationUtilities } from '@driscollsinc/login-module-ui';
import { DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';

import useCheckUserGroup from '../../demea/hooks/useCheckUserGroup';

const Home = (props) => {
    const location = useLocation();
    const { isOnlyDemeaUser } = useCheckUserGroup();
    const { authState } = useAuth(); 

    useEffect(() => {
        if (!location.pathname.includes('demea')) {
            import('../../index.scss');
        }
    }, [location]);

    useEffect(() => {
        props.setPageTitle('');
    }, []);

    const onSuccess = (res) => {
        if (res.status === 'SUCCESS') {
            return props.auth.redirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    };

    const onError = (err) => {
        console.log('error logging in', err);
    };

    return authState?.isAuthenticated ? (
        isOnlyDemeaUser() ? (
            <Navigate to="/demea" />
        ) : (
            <Navigate to="/InitializeApplication/" />
        )
    ) : (
        <React.Fragment>
            <DrcMain transparent>
                <DrcImage
                    src={require('../images/Wood_Rasp_Full_Hd.png')}
                    webp={require('../images/Wood_Rasp_Full_Hd.webp')}
                    style={{
                        position: 'absolute',
                        height: '100vh',
                        width: '100vw',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                        zIndex: -1
                    }}
                    alt=""
                />

                <DriscollsVContainer className={styles.loginContainer}>
                    <DriscollsLogin
                        baseUrl={window.config.OKTA_URL}
                        onSuccess={onSuccess}
                        onError={onError}
                        config={props.config ?? {}}
                        appVersion={process.env.REACT_APP_VERSION}
                    />
                </DriscollsVContainer>
            </DrcMain>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(null, mapDispatchToProps)(withAuth(Home));
