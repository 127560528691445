export const BYPASS_LOCATION_CONSTANTS = {
    BERRY_TYPE: 'BerryType',
    WAREHOUSE_NBR: 'WarehouseNbr',
    INSPECTIONS: 'Inspections',
    PERCENTAGE: 'Percentage',
    VARIETY: 'Variety',
    ESTIMATE_GROUP: 'EstimateGroup',
    BUSINESS_UNIT: 'BusinessUnit',
    DEMEA: 'DEMEA',
    BYPASS: 'Bypass',
    LAST_UPDATED_BY: 'LastUpdatedBy',
    LAST_UPDATED_DATE: 'LastUpdatedDateTime',
    DEMEA_ADMIN: 'DEV_QMP-DEMEA-Admin',
    BERRY_TYPE_BLACK: 'BLACK',
    ALL: 'All',
    NONE: 'None',
    LOCATION: 'Location',
    QUARTILE: 'Quartile',
    OVERRIDE: 'Override',
    CANCEL: 'Cancel',
    SUBMIT: 'Submit'
};
