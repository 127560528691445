import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from './dota/data/store';
import AllMiddleware from './dota/data/middleware';
import Application from './Application';
import { worker } from './mocks/browser';

interface WindowConfig {
    API_BASE_ADDRESS: string;
    ASSETS_URL: string;
    ENV: string;

    OKTA_URL: string;
    OKTA_ISSUER: string;
    OKTA_CLIENT_ID: string;

    OKTA_ADMIN_GROUPS: string;
    OKTA_REGULAR_GROUPS: string;
    OKTA_READ_ONLY_GROUPS: string;
    OKTA_DEMEA_ADMIN_GROUPS: string;
    OKTA_DEMEA_REGULAR_GROUPS: string;

    BERRY_TYPE: string;
    COUNTRY_OF_ORIGIN: string;
    GROWING_METHOD: string;
    VARIETY: string;
    WAREHOUSE: string;

    uiConfig: {
        v2UI: string;
    }

    APP_USE_MSW: boolean;
}

declare global {
    interface Window {
        config: WindowConfig;
    }
}

const root = createRoot(document.getElementById('root'));

const renderApp = () =>
    root.render(
        <Provider store={Store}>
            <Application />
        </Provider>
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./Application', renderApp);
}

AllMiddleware.ConfigureAllMiddleware();

if (window.config.APP_USE_MSW === true) {
    worker
        .start({
            onUnhandledRequest: 'bypass' // Ignore unhandled requests
        })
        .then(() => {
            renderApp();
        });
} else {
    renderApp();
}
